import React, { createContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '../Loading'
import { setToken } from '../../services/index'
import { globalConfig } from '../../config'

export const AuthContext = createContext({ userMetadata: null })

export const Auth = props => {
  const [userMetadata, setUserMetaData] = useState(null)

  const { user, getAccessTokenSilently } = useAuth0()

  const getUserMetadata = async () => {
    const baseURL = `https://${globalConfig.auth0.domain}/api/v2/`
    console.log('base url -> ' + baseURL);
    console.log('user sub -> ' + `${user.sub}`);
    const userDetailsByIdUrl = `${baseURL}users/${user.sub}`

    const accessToken = await getAccessTokenSilently({
      audience: baseURL,
      scope: 'read:current_user',
    })

	console.log('accessToken -> ' + accessToken)
	console.log(userDetailsByIdUrl);
    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

	console.log('metadataResponse -> ' + JSON.stringify(metadataResponse))
	//console.log('metadataResponse -> ' + metadataResponse.json())

    const { user_metadata: userMeta } = await metadataResponse.json()
    console.log("userMeta -> " + userMeta);
    if (userMeta)
	{
 		setToken(`${userMeta.apiKey}:${userMeta.userId}`)
    	setUserMetaData(userMeta)
    }
    else 
    {	
    	console.log('userMeta is null. Logging off')
    	//return <Logoff />
    }    	
  }

  useEffect(() => {
    getUserMetadata()
  }, [])

  return (
  	<>
    <AuthContext.Provider value={userMetadata}>
      {!userMetadata ? <Loading /> : props.children}
    </AuthContext.Provider>
    </>
  )
}
