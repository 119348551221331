/** @format */

export const globalConfig = {

  apiMainBaseURL: 'https://mblti.gazelleplatform.com/services/security',
  apiDatahubBaseURL:
    'https://mblti.gazelleplatform.com/services/security/api-proxy-service',
  apiDiagnosticBaseURL:
    'https://mblti.gazelleplatform.com/services/diagnostics',
  apiServicesUrl: 'https://mblti.gazelleplatform.com/services/api/',
  securityServicesUrl:
    'https://mblti.gazelleplatform.com/services/security/',
  sharedServicesUrl:
    'https://mblti.gazelleplatform.com/services/security/',

//   apiMainBaseURL: "https://msx-backend.gazelleplatform.com/services/security",
//   apiDatahubBaseURL:
//     "https://msx-backend.gazelleplatform.com/services/security/api-proxy-service",
//   apiDiagnosticBaseURL:
//     "https://msx-backend.gazelleplatform.com/services/diagnostics",
//   apiServicesUrl: "https://msx-backend.gazelleplatform.com/services/api/",
//   securityServicesUrl:
//     "https://msx-backend.gazelleplatform.com/services/security/",
//   sharedServicesUrl:
//     "https://msx-backend.gazelleplatform.com/services/security/",
  loginUrl: "https://dms.gazelleplatform.com/login",
  datahubHost: "datahub.gazelleplatform.com",
  logoutUrl:
    "https://gazelle-dms.us.auth0.com/v2/logout?client_id=nSXQIFBzQGGUZlWTDeNK7gJ65MnNRlmb&returnTo=https://dms.gazelleplatform.com/login",
  authUri:
    "https://gazelle-dms.auth0.com/authorize?response_type=code&client_id=nSXQIFBzQGGUZlWTDeNK7gJ65MnNRlmb&redirect_uri=https://origotelehealth.gazelleplatform.com/callback&scope=openid%20profile%20email&state=S75G5ASAR9SSSS8DSFSSDS53S63S7D5FHS8G89G80YH9RTJUY5GSKGAS2L42UTF5Y36F93F7462U",
  targetUrl: "https://dms.gazelleplatform.com/",
  userinfoUrl: "https://gazelle-dms.us.auth0.com/userinfo",
  state:
    "S75G5ASAR9SSSS8DSFSSDS53S63S7D5FHS8G89G80YH9RTJUY5GSKGAS2L42UTF5Y36F93F7462U",
  platformWelcomeMessage: "Welcome to the digital convergence experience",
  ehxClaimsTopicName: "gazelle.ehx.claims.queue",
  ehxClaimsServer: "amqp://192.168.192.6",
  bearerTokenValue:
    "B234A23N4S235A52SD35B34S6Z5K7L56FA87D69L79FK879A6S57F436K2JB42K34JS234FB2S3A423H4JF34B6K45A6S45F7B5K67A5S8J8D68F5H65S76A58F",
  userInfoUrl: "https://gazelle-dms.us.auth0.com/userinfo",
  developmentUrl: "http://localhost:3000",
  paginationLimit: 5,
  googleMapsApi: "AIzaSyCeNLUmMgY8Nw1aYy9jjyswHwrcK5p_dOg",
  // auth0: {
  //   domain: 'gazelle-mdx.us.auth0.com',
  //   clientId: 'PzKRy5kSDgmNyGnc092av3vN6L1Uw0Lx',
  // },
  auth0: {
    domain: "gazelle-dms.us.auth0.com",
    clientId: "ZQS6pdhS5gb9lkpwKFohBUzyuf39YqH5",
  },
  OPENTOK_URL: "https://tokbox.com/embed/embed/ot-embed.js?",
  NEO_SERVER_URL: "http://localhost:3001",
  DYNAMO_SERVER_URL: "http://localhost:3002",
  BREEZE_SERVER_URL: "http://localhost:3003",
  EQIUS_SERVER_URL: "http://localhost:3004",
  ABACUS_SERVER_URL: "http://localhost:3006",
  WAVES_SERVER_URL: "https://waves.gazelleplatform.com",
  DTS_SERVER_URL: "https://dts.gazelleplatform.com",
  LIBRARY_SERVER_URL: "http://localhost:3015",
  DTS_SERVER_URL: "http://localhost:3010",
};
