/** @format */

export const CUSTOMERS = "/customers";
// export const EDITORS = "/editors";
// export const BROADCASTERS = "/broadcasters";
// export const PRODUCERS = "/producers";
export const AUDIENCE = "/audience";
export const EXPERIENCE = "/experience";
export const DEALFLOW = "/dealflow";
export const SERVICE = "/service";
export const BUSINESSOFFICE = "/businessOffice";
export const INSIGHTS = "/insights";
export const DATA = "/data";
export const LIBRARY = "/library";

// export const ARTICLES = "/articles";
export const WORKFLOW = "/workflow";
export const WORKFLOW_EVENTS = "/workflow-events";

export const CONTENT = "/content";
export const APPS = "/apps";
export const PROJECTS = "/projects";
// export const COMPONENTS = "/components";
// export const NETWORK_CONTENT = "/network-content";
export const HOME = "/home";
export const CHAT = "/chat/:id";
export const SUBSCRIBERS = "/subscribers";
export const RATINGS = "/ratings";

//programming
// export const PROGRAMS = "/programs";
// export const SEGMENTS = "/segments";
// export const SHOWS = "/shows";
// export const SCHEDULER = "/scheduler";
// export const SERVICES = "/services";
// export const SPECTRUM = "/spectrum";
//clock
// export const MASTER_CLOCK = "/master-clock";
// export const TIMEBASE = "/timebase";
//data
export const TRIGGERS = "/triggers";
export const TRANSFORMATIONS = "/transformations";
export const CONNECTORS = "/content-input-source";
export const DATA_ANALYTICS = "/analytics";
export const MAP = "/map";

// Ehx Form Routes - Management
// export const UPDATE_REPORTER = "/reporters/:id";
// export const UPDATE_EDITOR = "/providers/:id";
// export const MANAGE_REPORTER = "/manage-reporter/:id";
// export const MANAGE_EDITOR = "/manage-editor/:id";
// export const MANAGE_BROADCASTER = "/manage-broadcaster/:id";
// export const MANAGE_PRODUCER = "/manage-producer/:id";
export const MANAGE_AUDIENCE = "/manage-audience/:id";

export const MANAGE_SUBSCRIBER = "/manage-subscriber";
//export const MANAGE_ARTICLE = "/manage-article/:id";

// export const MANAGE_CONTENT = "/manage-content/:id";
// export const MANAGE_NETWORK_CONTENT = "/manage-network-content/:id";

export const MANAGE_WORKFLOW = "/manage-workflow/:id";
export const MANAGE_WORKFLOW_EVENTS = "/manage-workflow-events/:id";

// ROUTES TO EQUIS FORMS
export const CREATE_CUSTOMER = "/create-customer";
//export const CREATE_CLIENT = "/create-client";
//programming
// export const MANAGE_PROGRAM = "/manage-program";
// export const MANAGE_SEGMENT = "/manage-segment";
// export const MANAGE_SHOW = "/manage-show";
export const MANAGE_SERVICE = "/manage-service";
//data
export const MANAGE_TRIGGERS = "/manage-triggers/:id";
export const MANAGE_TRANSFORMATION = "/manage-transformation/:id";
export const MANAGE_CONNECTORS = "/manage-content-input-source";

//legacy
// export const MANAGE_APPOINTMENT = '/manage-appointment'
// export const MANAGE_VISIT = '/manage-visit'
// export const MANAGE_PRESCRIPTION = '/manage-prescription'
// export const MANAGE_LAB = '/manage-lab'
export const MANAGE_PAYMENT = "/manage-payment";
// export const MANAGE_HEATHCARE_FACILITY = '/manage-healthcare-facility'

// Ehx Form Routes - Create
// export const CREATE_REPORTER = "/create-reporter";
// export const CREATE_EDITOR = "/create-provider";
// export const CREATE_BROADCASTER = "/create-broadcaster";
// export const CREATE_PRODUCER = "/create-producer";
export const CREATE_AUDIENCE = "/create-audience";

// export const CREATE_APPOINTMENT = "/appointments/create";
// export const CREATE_VISIT = "/create-visit";
// export const CREATE_PRESCRIPTION = "/create-prescription";
// export const CREATE_LAB = "/create-lab";
// export const CREATE_PAYMENT = "/create-payment";
// export const CREATE_ARTICLES = "/create-articles";

export const CREATE_CONTENT = "/create-content";
// export const CREATE_NETWORK_CONTENT = "/create-network-content";

export const CREATE_WORKFLOW = "/create-workflow";
export const CREATE_WORKFLOW_EVENTS = "/create-workflow-events";
//programming
// export const CREATE_PROGRAM = "/create-program";
// export const CREATE_SEGMENT = "/create-segment";
// export const CREATE_SHOW = "/create-show";
export const CREATE_SERVICE = "/create-service";
//data
export const CREATE_TRIGGER = "/create-trigger";
export const CREATE_TRANSFORMATION = "/create-transformation";
export const CREATE_CONNECTORS = "/create-content-input-source";

// Ehx Form Routes - Edit
// export const UPDATE_APPOINTMENT = "/appointments/:id";
// export const UPDATE_VISIT = "/visits/:id";
// export const UPDATE_ARTICLES = "/update-articles/:id";
export const UPDATE_WORKFLOW = "/update-workflow/:id";
export const UPDATE_WORKFLOW_EVENTS = "/update-workflow-events/:id";

// Ehx UserProfile routes
// export const PROVIDER_SETTINGS = "/provider-settings";
export const SUBSCRIBER_SETTINGS = "/subscriber-settings";
export const NOTIFICATIONS = "/notifications";
export const LOGOUT = "/logout";
export const ACCOUNTS = "/accounts";
export const USER_PROFILE = "/user-profile";
export const DOCUMENTS = "/docs";

//OX - Inventory Management System Routes 
export const PRODUCTS = "/products";
export const CREATE_PRODUCT = "/create-products";
export const MANAGE_PRODUCT = "/manage-products";
