import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthContext } from "../../Auth/Auth";

import * as ROUTES from "../../NavigationComponent/constants";
// icons
import ProviderIcon from "../Icon/Provider";
import MapsIcon from "../Icon/Maps";
import PatientIcon from "../Icon/Patient";
import VisitsIcon from "../Icon/Visits";
import Billing from "../Icon/Billing";
import AppointmentIcon from "../Icon/Appointment";
import FacilityIcon from "../Icon/Facilities";
import PaymentIcon from "../Icon/Payment";
import DocumentIcon from "../Icon/Document";
import NotificationsIcon from "../Icon/Notifications";
import SettingsIcon from "../Icon/Settings";
import ExitAppIcon from "../Icon/ExitApp";
import HomeIcon from "../Icon/Home";
import ChevronIcon from "../Icon/chevrondown";
import ChevronUpIcon from "../Icon/chevronUp";

export const SideNavLinks = ({ setToggleNav }) => {
  const { logout } = useAuth0();
  const userMetadata = useContext(AuthContext);
  const [experience, setExperience] = useState(false);
  const [dealFlow, setDealFlow] = useState(false);
  const [services, setServices] = useState(false);
  const [businessOffices, setBusinessOffices] = useState(false);
  const [insights, setInsights] = useState(false);
  const [dataTab, setDataTab] = useState(false);

  const isProvider = Number(userMetadata?.membershipType) === 3001;

  const closeSideBar = () => setToggleNav(false);
  return (
    <>
      <div className="sidenav__items mb-8">
        <NavLink
          to={ROUTES.HOME}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <HomeIcon className="icon" />
          <span className="name">Home</span>
        </NavLink>

        <NavLink
          onClick={closeSideBar}
          to={ROUTES.CUSTOMERS}
          activeClassName="sidenav__items__item--active"
          className="sidenav__items__item"
        >
          <center>Customers </center>
        </NavLink>

        <NavLink
          onClick={closeSideBar}
          to={ROUTES.PRODUCTS}
          activeClassName="sidenav__items__item--active"
          className="sidenav__items__item"
        >
          <center>Products</center>
        </NavLink>

{/* 
        <NavLink
          onClick={closeSideBar}
          to={ROUTES.DEALFLOW}
          activeClassName="sidenav__items__item--active"
          className="sidenav__items__item"
        >
          <center>Deal Flow</center>{" "}
        </NavLink>
*/}
        <NavLink
          onClick={closeSideBar}
          activeClassName="sidenav__items__item--active"
          to={ROUTES.SERVICE}
          className="sidenav__items__item"
        >
          <center>Services</center>{" "}
        </NavLink>
{/*
        <NavLink
          onClick={closeSideBar}
          activeClassName="sidenav__items__item--active"
          to={ROUTES.BUSINESSOFFICE}
          className="sidenav__items__item"
        >
          <center>Business Office</center>{" "}
        </NavLink>
*/}
        <NavLink
          onClick={closeSideBar}
          to={ROUTES.INSIGHTS}
          className="sidenav__items__item"
        >
          <center>Insights</center>{" "}
        </NavLink>

        <NavLink
          onClick={closeSideBar}
          activeClassName="sidenav__items__item--active"
          to={ROUTES.DATA}
          className="sidenav__items__item"
        >
          <center>Data</center>{" "}
        </NavLink>
        <NavLink
          onClick={closeSideBar}
          activeClassName="sidenav__items__item--active"
          to={ROUTES.LIBRARY}
          className="sidenav__items__item"
        >
          <center>Library</center>{" "}
        </NavLink>
        <NavLink
          to={ROUTES.LOGOUT}
          style={{ marginTop: "20px" }}
          onClick={() => logout()}
          className="sidenav__items__item mt-20"
        >
          <ExitAppIcon className="icon" />
          <span className="name">Logout</span>
        </NavLink>
      </div>
    </>
  );
};
